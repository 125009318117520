import { useState, useEffect, useRef } from 'react';
import {
  Heading,
  Container,
  Grid,
  Box,
  Text,
  useTheme,
  Radio,
  RadioGroup,
  Stack,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Icon,
  Button,
  Tooltip,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  UnorderedList,
  ListItem,
  Link,
} from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { isEmpty } from 'lodash';
import { useRecoilValue } from 'recoil';
import { currentDatabaseSelector } from '@transport-insights/uikit';
import {
  currencyFormatter,
} from '@src/utils/math';
import MainLayout from '@src/components/layouts/main';
import EmptyState from '@src/components/shared/EmptyState';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import { useMaintenanceCostComparisons } from '@src/modules/performance/context/reports-api-hooks';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import { FiCode, FiMoreHorizontal } from 'react-icons/fi';

const sortOptions = [
  {
    label: 'Name (A to Z)',
    value: 'nameAsc',
  },
  {
    label: 'Name (Z to A)',
    value: 'nameDesc',
  },
  {
    label: 'Dollars per km (High to Low)',
    value: 'dollarsPerKmDesc',
  },
  {
    label: 'Dollars per km (Low to High)',
    value: 'dollarsPerKmAsc',
  },
  {
    label: 'Dollars per lane km (High to Low)',
    value: 'dollarsPerLaneKmDesc',
  },
  {
    label: 'Dollars per lane km (Low to High)',
    value: 'dollarsPerLaneKmAsc',
  },
];

function MoreDrawer({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <>
      <Tooltip label="More Information">
        <IconButton ref={btnRef} onClick={onOpen} icon={<FiMoreHorizontal />} borderRadius="full" colorScheme="gray" ml={3} />
      </Tooltip>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            More Information
          </DrawerHeader>
          <DrawerBody>
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

function MaintenanceOperations() {
  const { data, isLoading, error } = useMaintenanceCostComparisons();
  const rca = useRecoilValue(currentDatabaseSelector);
  const wcOptions = [...new Set(data?.map((x) => x.wc))].sort();
  const peerGroups = [...new Set(data?.map((x) => x.peerGroup))].sort();
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [selectedPeerGroup, setSelectedPeerGroup] = useState(undefined);
  const [sortBy, setSortBy] = useState('nameAsc');
  const [filteredData, setFilteredData] = useState([]);
  const theme = useTheme();
  const errorStatus = error?.response?.status;

  useEffect(() => {
    if (selectedOption && selectedPeerGroup) {
      setFilteredData(
        data?.sort((a, b) => {
          switch (sortBy) {
            case 'nameAsc':
              return a.la.localeCompare(b.la);
            case 'nameDesc':
              return b.la.localeCompare(a.la);
            case 'dollarsPerKmDesc':
              return b.dollarsPerKm - a.dollarsPerKm;
            case 'dollarsPerKmAsc':
              return a.dollarsPerKm - b.dollarsPerKm;
            case 'dollarsPerLaneKmDesc':
              return b.dollarsPerLaneKm - a.dollarsPerLaneKm;
            case 'dollarsPerLaneKmAsc':
              return a.dollarsPerLaneKm - b.dollarsPerLaneKm;
            default:
              return a.la.localeCompare(b.la);
          }
        })
          .filter((x) => x.wc === selectedOption)
          .filter((x) => (selectedPeerGroup !== 'all' ? x.peerGroup === selectedPeerGroup : x))
          .map((item) => ({
            id: item.id,
            la: item.la,
            dollarsPerKm: item.dollarsPerKm,
            dollarsPerLaneKm: item.dollarsPerLaneKm,
            peerDollarsPerKm: item.peerDollarsPerKm,
            peerDollarsPerLaneKm: item.peerDollarsPerLaneKm,
            allDollarsPerKm: data.filter((x) => x.wc === selectedOption).reduce((acc, x) => acc + x.dollarsPerKm, 0) / data.filter((x) => x.wc === selectedOption).length,
            allDollarsPerLaneKm: data.filter((x) => x.wc === selectedOption).reduce((acc, x) => acc + x.dollarsPerLaneKm, 0) / data.filter((x) => x.wc === selectedOption).length,
          })),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, selectedPeerGroup, sortBy]);

  useEffect(() => {
    if (wcOptions.length > 0) {
      setSelectedOption(wcOptions[0]);
    }
    if (peerGroups.length > 0) {
      const currentRcaPeerGroup = data.find((x) => x.id === rca.id)?.peerGroup || undefined;
      setSelectedPeerGroup(currentRcaPeerGroup);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return (
      <MainLayout>
        <LoadingSpinner />
      </MainLayout>
    );
  }

  if (errorStatus === 401 || errorStatus === 500) {
    return (
      <MainLayout>
        <EmptyState
          title="Access Denied"
          message={(
            <>
              <Text color="gray.400">You do not have permission to view this page.</Text>
              <Text color="gray.400">Please sign in or select a different RCA.</Text>
            </>
            )}
        />
      </MainLayout>
    );
  }

  if (!isLoading && data && isEmpty(data)) {
    return (
      <MainLayout>
        <EmptyState
          title="No Data"
          message={(
            <>
              <Text color="gray.400">Sorry there is no data available for the selected network and year.</Text>
              <Text color="gray.400">Please try a different year and/or network.</Text>
            </>
            )}
        />
      </MainLayout>
    );
  }

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Flex justify="space-between" align="center" mb={4}>
          <Heading mb={2}>
            Maintenance Operations & Renewal
          </Heading>
          <MoreDrawer>
            <Text mb={4}>
              The page shows the costs per kilometre of network and costs per lane km for the selected Work Category (WC) code, for all Territorial Local Authorities (TLAs) in the selected Peer Group. To see the costs for all TLAs, select &apos;All&apos;.
            </Text>
            <Text mb={4}>
              The TLA you have currently selected at the top of Transport Insights will show in orange, the other TLAs will show in blue.
            </Text>
            <Text mb={4}>
              If you select the Total Costs option instead of an individual Work Category code, the costs reported per TLA are the sum of the following Work Category codes: 111, 112, 113, 114, 121, 122, 123, 124, 131, 151, 161, 211, 212, 213, 214, 215, 221, 222, and 231. Note that not all of these codes may be available to view individually.
            </Text>
            <Text mb={4}>
              The data for this page has been provided by NZ Transport Agency.
            </Text>
            <Text fontWeight="bold" mb={4}>Useful links:</Text>
            <UnorderedList>
              <ListItem>
                <Link
                  href="https://www.nzta.govt.nz/planning-and-investment/planning-and-investment-knowledge-base/202124-nltp/2021-24-nltp-activity-classes-and-work-categories/"
                  target="_blank"
                >
                  2021-24 NLTP activity classes and work categories
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="https://www.nzta.govt.nz/planning-and-investment/planning-and-investment-knowledge-base/2024-27-nltp-investment-requirements/2024-27-nltp-activity-classes-and-work-categories/"
                  target="_blank"
                >
                  2024-27 NLTP activity classes and work categories
                </Link>
              </ListItem>
            </UnorderedList>
          </MoreDrawer>
        </Flex>
        <Grid
          gap={2}
          templateColumns="repeat(6, 1fr)"
          position="relative"
          mb={4}
        >
          {wcOptions.map((item) => (
            <Box
              key={item}
              background={item === selectedOption ? 'white' : 'gray.50'}
              shadow="sm"
              borderRadius="md"
              p={2}
              border="1px solid"
              borderColor={item === selectedOption ? 'brand.orange.400' : 'gray.200'}
              _hover={{
                borderColor: item === selectedOption ? null : 'brand.orange.400',
                cursor: item === selectedOption ? null : 'pointer',
              }}
              onClick={() => { setSelectedOption(item); window.scrollTo(0, 0); }}
              position="relative"
            >
              <Text fontSize="sm" fontWeight="bold">{item.split('-')[0]}</Text>
              <Text fontSize="10px" color="gray.600">{item.split('-')[1]}</Text>
            </Box>
          ))}
        </Grid>
        <Flex
          p={4}
          shadow="sm"
          background="white"
          borderRadius="md"
          mb={4}
          border="1px solid"
          borderColor="gray.100"
          justify="space-between"
          zIndex="500"
        >
          <Box>
            <Text mb={2} fontWeight="bold">Filter by Peer Group:</Text>
            <RadioGroup onChange={setSelectedPeerGroup} value={selectedPeerGroup} mt={4}>
              <Stack direction="row" spacing="6">
                <Radio value="all">All</Radio>
                {peerGroups.map((x, idx) => <Radio value={x} key={idx}>{x}</Radio>)}
              </Stack>
            </RadioGroup>
          </Box>
          <Box textAlign="right" zIndex="500">
            <Text mb={2} fontWeight="bold">Order results by:</Text>
            <Menu
              closeOnSelect={false}
            >
              <MenuButton
                ml={2}
                as={Button}
                variant="outline"
                colorScheme="gray"
                size="sm"
                leftIcon={<Icon as={FiCode} boxSize={4} transform="rotate(90deg)" />}
              >
                { sortOptions.find((x) => x.value === sortBy)?.label }
              </MenuButton>
              <MenuList>
                <MenuOptionGroup
                  onChange={(value) => setSortBy(value)}
                  defaultValue={sortBy}
                  value={sortBy}
                  type="radio"
                >
                  {sortOptions.map((item) => (
                    <MenuItemOption key={item.value} value={item.value}>{item.label}</MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
        <ChartContainer>
          <Chart
            options={
                  {
                    title: {
                      text: `${selectedOption} - 3 year average (2020/21–2022/23)`,
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: true,
                      position: 'top',
                    },
                    yaxis: [
                      {
                        title: {
                          text: 'Cost per km/lane km',
                        },
                        labels: {
                          formatter(value) {
                            return currencyFormatter.format(value);
                          },
                        },
                      },
                    ],
                    xaxis:
                      {
                        title: {
                          text: selectedPeerGroup !== 'all' ? selectedPeerGroup : '',
                        },
                        labels: {
                          show: true,
                          rotate: -45,
                          hideOverlappingLabels: false,
                        },
                      },
                    colors: [theme.colors.brand.blue[700], theme.colors.brand.blue[300]],
                    tooltip: {
                      enabled: true,
                      shared: true,
                      intersect: false,
                      marker: {
                        show: false,
                      },
                      y: {
                        formatter(value) {
                          return currencyFormatter.format(value);
                        },
                      },
                    },
                    plotOptions: {
                      bar: {
                        distributed: false,
                        horizontal: false,
                      },
                    },
                    annotations: {
                      yaxis: [
                        {
                          y: selectedPeerGroup === 'all' ? filteredData?.[0]?.allDollarsPerKm : filteredData?.[0]?.peerDollarsPerKm,
                          strokeDashArray: 8,
                          borderColor: theme.colors.green[600],
                          label: {
                            borderColor: theme.colors.green[600],
                            style: {
                              color: '#fff',
                              background: theme.colors.green[600],
                            },
                            text: `Average per km ${currencyFormatter.format(selectedPeerGroup === 'all' ? filteredData?.[0]?.allDollarsPerKm : filteredData?.[0]?.peerDollarsPerKm)}`,
                          },
                        },
                        {
                          y: selectedPeerGroup === 'all' ? filteredData?.[0]?.allDollarsPerLaneKm : filteredData?.[0]?.peerDollarsPerLaneKm,
                          strokeDashArray: 8,
                          borderColor: theme.colors.purple[600],
                          label: {
                            borderColor: theme.colors.purple[600],
                            style: {
                              color: '#fff',
                              background: theme.colors.purple[600],
                            },
                            text: `Average per lane km ${currencyFormatter.format(selectedPeerGroup === 'all' ? filteredData?.[0]?.allDollarsPerLaneKm : filteredData?.[0]?.peerDollarsPerLaneKm)}`,
                          },
                        },
                      ],
                    },
                  }
                }
            series={
                  [
                    {
                      name: 'Dollars per km',
                      data: filteredData.map((item) => ({
                        x: item.la,
                        y: item.dollarsPerKm,
                        fillColor: item.id === rca.id ? theme.colors.brand.orange[400] : theme.colors.brand.blue[700],
                      })),
                    },
                    {
                      name: 'Dollars per lane km',
                      data: filteredData.map((item) => ({
                        x: item.la,
                        y: item.dollarsPerLaneKm,
                        fillColor: item.id === rca.id ? theme.colors.brand.orange[200] : theme.colors.brand.blue[300],
                      })),
                    },
                  ]
                }
            type="bar"
            width="100%"
            height="470"
          />
        </ChartContainer>
      </Container>
    </MainLayout>
  );
}

export default MaintenanceOperations;
