import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Center,
  CircularProgress,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Progress,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import EmptyState from '@src/components/shared/EmptyState';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import { useEffect, useMemo, useState } from 'react';
import { QUARTERS } from '@src/modules/performance/shared/constants';
import { FiAlertCircle, FiArrowRight, FiCalendar } from 'react-icons/fi';
import { useQueryClient } from 'react-query';
import { useConfirm } from '@src/hooks/useConfirm';
import {
  useActivateTtmResult, useDeactivateTtmResult, useTtmReport, useTtmReportOptions,
} from '../../context/temporary-traffic-management-api-hooks';
import TtmRcaContacts from './components/TtmRcaContacts';
import { calculateProgress } from './helpers';
import TtmDevTools from './components/TtmDevTools';
import useTemporaryTrafficManagementApi from '../../api/useTemporaryTrafficManagementApi';
import TtmGeneratedReports from './components/TtmGeneratedReports';
import { roundKpiValue } from '../../shared/helpers';

const STATUS_COLOR_MAP = {
  'Not Started': 'gray',
  Started: 'orange',
  Complete: 'green',
};

const STATUSES = ['Not Started', 'Started', 'Complete'];

export default function TtmReporting() {
  const api = useTemporaryTrafficManagementApi();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: reportOptions, isLoading: isLoadingOptions } = useTtmReportOptions();
  // Extract financial years from the report options
  const financialYears = useMemo(
    () => [...new Set(reportOptions?.quarters?.map(({ lockYear }) => lockYear))],
    [reportOptions?.quarters],
  );
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(financialYears[0]);
  // Extract available quarters for the selected financial year
  const availableQuarters = useMemo(
    () => reportOptions?.quarters?.filter(({ lockYear }) => lockYear === selectedFinancialYear)
      .sort((a, b) => a.quarterNumber - b.quarterNumber),
    [selectedFinancialYear, reportOptions?.quarters],
  );
  const [selectedQuarter, setSelectedQuarter] = useState(availableQuarters?.[0]?.quarterNumber || 1);
  const { data, isLoading: isLoadingReport, error } = useTtmReport(selectedFinancialYear, selectedQuarter);
  const errorStatus = error?.response?.status;
  const isLoading = isLoadingReport && isLoadingOptions;

  const pageName = 'Temporary Traffic Management Reporting';
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState(null);
  const percentComplete = useMemo(() => {
    if (!data?.rcaTtmCollectionStatus) {
      return 0;
    }
    const completed = data.rcaTtmCollectionStatus.filter(({ status }) => status === 'Complete').length;
    return (completed / data.rcaTtmCollectionStatus.length) * 100;
  }, [data?.rcaTtmCollectionStatus]);
  const isPublished = useMemo(() => (
    data?.rcaTtmCollectionStatus?.some(({ publishDate }) => publishDate !== null)
  ), [data?.rcaTtmCollectionStatus]);
  const [isTestMode, setIsTestMode] = useState(false);

  // Filter the data based on the selected status
  const filteredData = useMemo(() => (
    data?.rcaTtmCollectionStatus?.filter(({ status }) => (
      selectedStatus ? status === selectedStatus : true
    ))
  ), [data, selectedStatus]);

  useEffect(() => {
    if (financialYears?.length && !selectedFinancialYear) {
      setSelectedFinancialYear(financialYears[0]);
    }
  }, [financialYears, selectedFinancialYear]);

  useEffect(() => {
    if (availableQuarters?.length) {
      setSelectedQuarter(availableQuarters[0].quarterNumber);
    }
  }, [availableQuarters]);

  useEffect(() => {
    setSelectedStatus(null);
  }, [selectedFinancialYear, selectedQuarter]);

  // Render the entry date details based on the current phase
  const {
    progress, phase, daysUntilEnd,
  } = calculateProgress(data?.quarter?.entryStartDate, data?.quarter?.entryEndDate);

  const renderPhaseDetails = () => {
    switch (phase) {
      case 'before':
        return (
          <HStack
            px={2}
            py={1}
            borderRadius="base"
            border="1px solid"
            borderColor="gray.100"
            align="center"
          >
            <Icon as={FiCalendar} color="gray.300" mr={1} />
            <Text fontWeight="bold" fontSize="sm">
              {new Date(data?.quarter.entryStartDate).toLocaleDateString('en-NZ', {
                weekday: 'short', day: 'numeric', month: 'short', year: 'numeric',
              })}
            </Text>
            <Icon as={FiArrowRight} />
            <Text fontWeight="bold" fontSize="sm">
              {new Date(data?.quarter.entryEndDate).toLocaleDateString('en-NZ', {
                weekday: 'short', day: 'numeric', month: 'short', year: 'numeric',
              })}
            </Text>
          </HStack>
        );
      case 'during':
        return (
          <Box w="300px">
            <Flex justify="space-between" align="center">
              <Text fontSize="sm" mr={4}>
                Closes on
                {' '}
                {new Date(data?.quarter?.entryEndDate).toLocaleDateString('en-NZ', { dateStyle: 'medium' })}
              </Text>
              {daysUntilEnd > 0 ? (
                <Text fontSize="sm" fontWeight="bold">
                  {daysUntilEnd}
                  {' '}
                  {daysUntilEnd > 1 ? 'days' : 'day'}
                  {' '}
                  left
                </Text>
              ) : (
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Last day today
                </Text>
              )}
            </Flex>
            <Progress
              borderRadius="full"
              value={progress}
              colorScheme="red"
              size="sm"
              mt={2}
              width="100%"
            />
          </Box>
        );
      case 'after':
        return (
          <Flex border="1px solid" borderColor="red.100" p={2} borderRadius="base">
            <Icon as={FiAlertCircle} boxSize={4} color="red.600" />
            <Text color="red.600" fontSize="xs" ml={2}>
              Entry period has ended. Opened on
              {' '}
              {new Date(data?.quarter.entryStartDate).toLocaleDateString('en-NZ', { dateStyle: 'medium' })}
              {' '}
              and closed on
              {' '}
              {new Date(data?.quarter.entryEndDate).toLocaleDateString('en-NZ', { dateStyle: 'medium' })}
            </Text>
          </Flex>
        );
      default:
        return null;
    }
  };

  // Render the activate/deactivate button based on the current phase

  const { confirm, ConfirmDialog } = useConfirm();
  const { mutateAsync: doActivateTtmResult, isLoading: isActivating } = useActivateTtmResult();
  const { mutateAsync: doDeactivateTtmResult, isLoading: isDeactivating } = useDeactivateTtmResult();

  const handleActivateResults = async () => {
    const confirmed = await confirm({
      title: 'Are you sure you want to activate the results?',
      body: 'The RCA results for the quarter will show on the dashboard when activated.',
      confirmButtonLabel: 'Activate Results',
    });

    if (confirmed) {
      doActivateTtmResult({ quarterNumber: data.quarter.quarterNumber, lockYear: data.quarter.lockYear });
    }
  };

  const handleDeactivateResults = async () => {
    const confirmed = await confirm({
      title: 'Are you sure you want to deactivate the results?',
      body: 'The RCA results for the quarter will no longer show on the dashboard when deactivated.',
      confirmButtonLabel: 'Deactivate Results',
    });

    if (confirmed) {
      doDeactivateTtmResult({ quarterNumber: data.quarter.quarterNumber, lockYear: data.quarter.lockYear });
    }
  };

  const renderActivateButton = () => (
    <>
      {isPublished && (
      <Button
        onClick={() => handleDeactivateResults()}
        variant="solid"
        isDisabled={phase !== 'after' || isLoadingReport}
        isLoading={isDeactivating}
      >
        Deactivate Results
      </Button>
      )}
      <Button
        onClick={() => handleActivateResults()}
        variant="solid"
        isDisabled={phase !== 'after' || isLoadingReport}
        isLoading={isActivating}
      >
        Activate Results
      </Button>
    </>
  );

  // Generate the report
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const handleGenerateReport = async () => {
    const fileName = `TTM_Report_${data?.quarter.lockYear}_Q${data?.quarter.quarterNumber}_(${new Date().toLocaleString('en-NZ', { timeZone: 'Pacific/Auckland', dateStyle: 'medium', timeStyle: 'short' })})`;
    try {
      setIsGeneratingReport(true);
      await api.generateTtmReport(data?.quarter.lockYear, data?.quarter.quarterNumber)
        .then((res) => {
          const file = new Blob(
            [res],
            { type: 'text/csv;charset=utf-8' },
          );
          const url = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${fileName}.csv`;
          link.click();
        });
    } catch {
      toast({
        title: 'Error',
        description: 'An error occurred while generating the report.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    // Invalidate queries to refresh the report list
    queryClient.invalidateQueries('temporary-traffic-management-report');
    setIsGeneratingReport(false);
  };

  if ([401, 403].includes(errorStatus)) {
    return (
      <Container maxW="full" flexDirection="column" display="flex" flexGrow={1}>
        <HStack mb={6} width="100%" justify="space-between">
          <Heading as="h1">{pageName}</Heading>
        </HStack>
        <EmptyState
          title="Access Denied"
          message={(
            <Text color="gray.400">You do not have permission to view this page.</Text>
          )}
        />
      </Container>
    );
  }

  if ([500].includes(errorStatus)) {
    return (
      <Container maxW="full" flexDirection="column" display="flex" flexGrow={1}>
        <HStack mb={6} width="100%" justify="space-between">
          <Heading as="h1">{pageName}</Heading>
        </HStack>
        <EmptyState
          title="Unknown Error"
          message={(
            <Text color="gray.400">
              Oops! Something went wrong on our end.
              <br />
              Please try again later or contact support if the issue persists.
            </Text>
          )}
        />
      </Container>
    );
  }

  if (isLoading || isLoadingOptions) {
    return (
      <Container maxW="full" flexDirection="column" display="flex" flexGrow={1}>
        <HStack mb={6} width="100%" justify="space-between">
          <Heading as="h1">{pageName}</Heading>
        </HStack>
        <LoadingSpinner />
      </Container>
    );
  }

  if (!reportOptions || reportOptions?.quarters?.length === 0) {
    return (
      <Container maxW="full" flexDirection="column" display="flex" flexGrow={1}>
        <HStack mb={6} width="100%" justify="space-between">
          <Heading as="h1">{pageName}</Heading>
        </HStack>
        <EmptyState
          title="No Data Available"
          message={(
            <Text color="gray.400">No data has been entered. Please check later.</Text>
          )}
        />
      </Container>
    );
  }

  return (
    <Container maxW="full" flexDirection="column" display="flex" flexGrow={1}>
      <HStack mb={6} width="100%" justify="space-between">
        <Heading as="h1">{pageName}</Heading>
      </HStack>
      <TtmDevTools onChangeMockDate={() => {}} onChangeTestMode={() => setIsTestMode(!isTestMode)} />
      <TableContainer
        background="white"
        boxShadow="base"
        borderRadius="base"
      >
        <HStack borderBottom="1px solid" borderColor="gray.100" width="100%" justify="space-between" padding={4}>
          <HStack>
            <Select
              width={44}
              value={selectedFinancialYear}
              size="sm"
              onChange={(e) => setSelectedFinancialYear(e.target.value)}
            >
              {financialYears.map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </Select>
            <Select
              width={44}
              value={selectedQuarter}
              size="sm"
              onChange={(e) => setSelectedQuarter(Number(e.target.value))}
            >
              {availableQuarters?.map(({ quarterNumber }) => (
                <option key={quarterNumber} value={quarterNumber}>{QUARTERS.find((x) => x.id === quarterNumber)?.label}</option>
              ))}
            </Select>
            <ButtonGroup size="sm">
              <Button
                colorScheme={selectedStatus === null ? 'orange' : 'gray'}
                onClick={() => setSelectedStatus(null)}
                variant={selectedStatus === null ? 'solid' : 'outline'}
              >
                All
              </Button>
              {STATUSES.map((status) => (
                <Button
                  key={status}
                  onClick={() => setSelectedStatus(status)}
                  variant={selectedStatus === status ? 'solid' : 'outline'}
                  colorScheme={selectedStatus === status ? 'orange' : 'gray'}
                >
                  {status}
                </Button>
              ))}
            </ButtonGroup>
          </HStack>
          <ButtonGroup size="sm">
            {data?.generatedReports?.length > 0 && (
              <>
                <TtmGeneratedReports isOpen={isOpen} onClose={onClose} reportList={data?.generatedReports} />
                <Button
                  onClick={() => onOpen()}
                  variant="ghost"
                >
                  View Generated Reports (
                  {data?.generatedReports?.length}
                  )
                </Button>
              </>
            )}
            <Button
              onClick={handleGenerateReport}
              variant="outline"
              isLoading={isGeneratingReport}
            >
              Generate Report
            </Button>
            {renderActivateButton()}
          </ButtonGroup>
        </HStack>
        {isLoadingReport && (
          <Center py={16}>
            <LoadingSpinner />
          </Center>
        )}
        {filteredData?.length === 0 && !isLoadingReport && (
          <Center py={16}>
            <Text>
              No results available for the selected status.
            </Text>
          </Center>
        )}
        {filteredData?.length > 0 && (
          <>
            <HStack
              px={4}
              py={3}
              borderBottom="1px solid"
              borderColor="gray.100"
              width="100%"
              spacing={6}
              justify="space-between"
            >
              <HStack align="center" spacing={4}>
                <Text fontWeight="bold">
                  {filteredData.length}
                  {' '}
                  {filteredData.length > 1 ? 'results' : 'result'}
                  {' '}
                  found
                </Text>
                <HStack>
                  <CircularProgress value={percentComplete} size={6} color="green.500" thickness="16px" />
                  <Text fontSize="xs">
                    {percentComplete.toFixed(0)}
                    % Complete
                  </Text>
                </HStack>
              </HStack>
              {renderPhaseDetails()}
            </HStack>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Status</Th>
                  <Th>RCA Name</Th>
                  <Th textAlign="right">NLTP Cost ($000&apos;s)</Th>
                  <Th textAlign="right">TTM Cost ($000&apos;s)</Th>
                  <Th textAlign="right">TTM Cost %</Th>
                  <Th>Confidence in TTM Cost</Th>
                  <Th textAlign="right">Total Site Inspections</Th>
                  <Th textAlign="right">Redundant Sites</Th>
                  <Th>Activated On</Th>
                  <Th>Contact Details</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData?.map(({
                  status, rcaName, nltpCost, ttmPercentage, ttmCost, ttmCostConfidence, completedSiteInspections, unattendedSiteInspections, contacts, publishDate,
                }) => (
                  <Tr key={rcaName}>
                    <Td><Badge colorScheme={STATUS_COLOR_MAP[status]}>{status}</Badge></Td>
                    <Td>{rcaName}</Td>
                    <Td textAlign="right">
                      {nltpCost !== null ? `$${nltpCost?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                      })}` : ''}
                    </Td>
                    <Td textAlign="right">
                      {ttmCost !== null ? `$${ttmCost?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                      })}` : ''}
                    </Td>
                    <Td textAlign="right">
                      {ttmPercentage !== null ? `${roundKpiValue(ttmPercentage, 5)}%` : ''}
                    </Td>
                    <Td textAlign="center">
                      {ttmCostConfidence ? `${ttmCostConfidence} / 5` : ''}
                    </Td>
                    <Td textAlign="right">{completedSiteInspections}</Td>
                    <Td textAlign="right">{unattendedSiteInspections}</Td>
                    <Td>
                      {publishDate ? new Date(publishDate).toLocaleDateString('en-NZ') : ''}
                    </Td>
                    <Td>
                      {contacts.length > 0 && (
                      <Button
                        size="xs"
                        variant="link"
                        colorScheme="blue"
                        onClick={() => setSelectedContacts({
                          rcaName,
                          contacts,
                        })}
                      >
                        View Contacts
                      </Button>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </>
        )}
      </TableContainer>
      {selectedContacts && (
        <TtmRcaContacts
          {...selectedContacts}
          isOpen
          onClose={() => setSelectedContacts(null)}
        />
      )}
      <ConfirmDialog />
    </Container>
  );
}
