import { TrafficLightColours as COLOURS } from '@src/modules/performance/shared/constants';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import { useTemporaryTrafficManagementKpi } from '../../context/temporary-traffic-management-api-hooks';
import { mapValueToColourRange, roundKpiValue } from '../../shared/helpers';

export default function TemporaryTrafficManagementWidget() {
  const { data: ttmData, isLoading, isError } = useTemporaryTrafficManagementKpi();

  const title = 'Temporary Traffic\nManagement';
  const description = 'NLTP Expenditure (%)';

  const color = mapValueToColourRange(ttmData?.value, 'temporaryTrafficManagement');

  // If there is an error and no data we want to hide the widget
  if (isError && (!ttmData || typeof ttmData.value !== 'number')) return null;

  return (
    <Widget
      link={null}
      title={title}
      description={description}
      lockYear={ttmData?.quadLockYear}
      quarter={ttmData?.quarter}
      isLoading={isLoading && !ttmData}
    >
      <CircleIndicator
        size={100}
        colour={COLOURS[color]}
        value={ttmData?.value !== undefined ? `${roundKpiValue(ttmData?.value, 5, 1)}%` : null}
        isLoading={isLoading || !ttmData}
      />
    </Widget>
  );
}
