import { useEffect, useState } from 'react';
import PieChart from '../charts/PieChart';

const colourMap = (value) => {
  if (value <= -1.0) return 'RED';
  if (value >= 1.0) return 'GREEN';
  if (value >= 0.5) return 'ORANGE';
  return 'GREY';
};

function HeaderServicePerformance({ queryInfo }) {
  const [colours, setColours] = useState(['GREY']);
  const [data, setData] = useState([1]);
  console.log(queryInfo);

  useEffect(() => {
    if (queryInfo.data) {
      const v = [];
      const c = [];
      queryInfo.data.sort((a, b) => b.index - a.index).forEach((r) => {
        c.push(colourMap(r.index));
        v.push(r.count > 0 ? r.count / 10 : r.count);
      });
      setColours(c);
      setData(v);
    }
  }, [queryInfo]);

  if (!queryInfo?.data || queryInfo?.data.length === 0) {
    return null;
  }

  return (
    <PieChart height={65} width={85} values={data} colours={colours} />
  );
}

export default HeaderServicePerformance;
