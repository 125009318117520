import {
  Container,
  SimpleGrid,
  Heading,
  HStack,
  Text,
  Switch,
  Icon,
  Button,
  Box,
  IconButton,
  Flex,
  Stack,
} from '@chakra-ui/react';
import {
  DndContext, DragOverlay, PointerSensor, closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext, rectSortingStrategy, useSortable, arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { selectedDatabaseIdState, useIsUserInRole } from '@transport-insights/uikit';
import MainLayout from '@src/components/layouts/main/MainLayout';
import E3Widget from '@src/modules/performance/components/DashboardWidgets/E3Widget';
import SafetyWidget from '@src/modules/performance/components/DashboardWidgets/SafetyWidget';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  FiEye, FiEyeOff, FiRotateCw, FiSliders,
} from 'react-icons/fi';
import {
  createElement, useEffect, useRef, useState,
} from 'react';
import { useConfirm } from '@src/hooks/useConfirm';
import AmenityWidget from '../../components/DashboardWidgets/AmenityWidget';
import DeliveryPerformanceWidget from '../../components/DashboardWidgets/DeliveryPerformanceWidget';
import CoinvestorAssuranceWidget from '../../components/DashboardWidgets/CoinvestorAssuranceWidget';
import ActivityManagementWidget from '../../components/DashboardWidgets/ActivityManagementWidget';
import ServicePerformanceWidget from '../../components/DashboardWidgets/ServicePerformanceWidget';
import TerritorialActivityWidget from '../../components/DashboardWidgets/TerritorialActivityWidget';
import NetworkPhysicalCharacteristicsWidget from '../../components/DashboardWidgets/NetworkPhysicalCharacteristicsWidget';
import RoadNetworkUseWidget from '../../components/DashboardWidgets/RoadNetworkUseWidget';
import NetworkAvailabilityAccessibilityWidget from '../../components/DashboardWidgets/NetworkAvailabilityAccessibilityWidget';
import PublicTransportWidget from '../../components/DashboardWidgets/PublicTransportWidget';
import DeliveryWidget from '../../components/DashboardWidgets/DeliveryWidget';
import StatsWidget from '../../components/DashboardWidgets/StatsWidget';
import { dashboardViewState, customDashboardLayoutState } from '../../state';
import TemporaryTrafficManagementWidget from '../../components/DashboardWidgets/TemporaryTrafficManagementWidget';
import { useAllowedReportsList } from '../../hooks/useReportsList';
import RoadMaintenanceWidget from '../../components/DashboardWidgets/RoadMaintenanceWidget';
import NetworkAssetManagementWidget from '../../components/DashboardWidgets/NetworkAssetManagementWidget';
import InfoScroller from '../reports/components/information/InfoScroller';
import InfoContentCollapse from '../reports/components/information/InfoContentCollapse';
import helpHtml from './help.html';

let widgetsMap = {
  activityManagement: ActivityManagementWidget,
  delivery: DeliveryWidget,
  deliveryPerformance: DeliveryPerformanceWidget,
  amenityAndCondition: AmenityWidget,
  safety: SafetyWidget,
  networkPhysicalCharacteristics: NetworkPhysicalCharacteristicsWidget,
  roadNetworkUse: RoadNetworkUseWidget,
  networkAvailability: NetworkAvailabilityAccessibilityWidget,
  publicTransport: PublicTransportWidget,
  territorialActivity: TerritorialActivityWidget,
  servicePerformance: ServicePerformanceWidget,
  coinvestorAssurance: CoinvestorAssuranceWidget,
  temporaryTrafficManagement: TemporaryTrafficManagementWidget,
};

const devWidgets = {
  efficiencyEffectivenessEconomy: E3Widget,
  roadMaintenance: RoadMaintenanceWidget,
  networkAssetManagement: NetworkAssetManagementWidget,
};

function SortableItem(props) {
  const {
    id, children, isCustomising, isHidden,
  } = props;
  const {
    attributes, listeners, setNodeRef, transform, transition, isDragging,
  } = useSortable({ id, disabled: !isCustomising || isHidden });

  const style = {
    position: 'relative',
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isCustomising && !isHidden ? 'grab' : 'default',
    background: isDragging ? '#f1f1f1' : 'inherit',
    border: isDragging ? '1px dashed' : 'inherit',
    borderColor: isDragging ? '#999' : 'transparent',
    borderRadius: 6,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {isCustomising && (
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={100}
      />
      )}
      {isDragging ? null : children}
    </Box>
  );
}

export default function Dashboard() {
  const hasDeveloperRole = useIsUserInRole('Developer');
  const viewType = useRecoilValue(dashboardViewState);
  const customDashboardLayout = useRecoilValue(customDashboardLayoutState);
  const setViewType = useSetRecoilState(dashboardViewState);
  const setCustomDashboardLayout = useSetRecoilState(customDashboardLayoutState);
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const reportsList = useAllowedReportsList(rcaId);
  const [isCustomising, setIsCustomising] = useState(false);
  // These are the default reports for the selected view type
  const defaultReportsList = Object.keys(reportsList).filter((key) => reportsList[key].viewType.includes(viewType) && reportsList[key].enabled);
  // This is the list of all reports that are enabled
  const allReportsList = Object.keys(reportsList).filter((key) => reportsList[key].enabled);
  const isCustomView = customDashboardLayout.widgetList.length > 0;
  const pageTitle = viewType === 'advanced' || isCustomView ? 'Performance Dashboard' : 'Governance Dashboard';
  const [widgetList, setWidgetList] = useState([]);
  const [tempShowNetworkStats, setTempShowNetworkStats] = useState(customDashboardLayout.showNetworkStats);
  const [activeId, setActiveId] = useState(null);
  const [hiddenReports, setHiddenReports] = useState(allReportsList.filter((key) => !widgetList.includes(key)));

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setWidgetList((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const handleSetVisible = (key) => {
    setWidgetList([...widgetList, key]);
  };

  const handleSetHidden = (key) => {
    setWidgetList(widgetList.filter((item) => item !== key));
  };

  const handleSaveLayout = () => {
    setCustomDashboardLayout({ widgetList: [...widgetList], showNetworkStats: tempShowNetworkStats });
    setIsCustomising(false);
    setViewType('custom');
  };

  const handleCancelCustomise = () => {
    setWidgetList(customDashboardLayout.widgetList.length > 0 ? [...customDashboardLayout.widgetList] : [...defaultReportsList]);
    setIsCustomising(false);
  };

  const { confirm, ConfirmDialog } = useConfirm();
  const handleResetLayout = async () => {
    const confirmed = await confirm({
      title: 'Are you sure you want to reset the layout?',
      body: 'The layout will be reset to the default view, this cannot be undone.',
      confirmButtonLabel: 'Reset Layout',
    });

    if (confirmed) {
      setCustomDashboardLayout({ widgetList: [], showNetworkStats: true });
      setWidgetList([...defaultReportsList]);
      setIsCustomising(false);
      setViewType('basic');
    }
  };

  // TEMP: Set the view type to advanced if the user does not have the Developer role
  // This can be removed when the new dashboard goes live
  useEffect(() => {
    if (!hasDeveloperRole) {
      setViewType('advanced');
    } else {
      // Add developer widgets to the widget map
      widgetsMap = { ...widgetsMap, ...devWidgets };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customDashboardLayout.widgetList.length > 0) {
      setWidgetList([...customDashboardLayout.widgetList]);
    } else {
      setWidgetList([...defaultReportsList]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType, rcaId]);

  useEffect(() => {
    setHiddenReports(allReportsList.filter((key) => !widgetList.includes(key)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetList]);

  useEffect(() => {
    if (customDashboardLayout.widgetList.length > 0) {
      setWidgetList([...customDashboardLayout.widgetList]);
    }
  }, [customDashboardLayout]);

  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50" isCustomising={isCustomising}>
      <Container
        maxW="full"
        flexDirection="column"
        display="flex"
        height="100%"
        flexGrow={1}
        position="relative"
      >
        {isCustomising && (
        <Flex
          position="sticky"
          right={4}
          p={2}
          pl={3}
          top={4}
          zIndex={999}
          background="white"
          boxShadow="lg"
          borderRadius="base"
          justify="space-between"
          align="center"
          mb={4}
        >
          <HStack>
            <Icon as={FiSliders} boxSize={6} />
            <Text fontWeight="bold">
              Customise Layout:
              {' '}
              <Text as="span" fontWeight="normal">Drag tiles to rearrange their order, or click the eye icon to show or hide them.</Text>
            </Text>
          </HStack>
          <HStack>
            <Button
              variant="solid"
              size="sm"
              colorScheme="brand.orange"
              onClick={() => handleSaveLayout()}
            >
              Save Custom Layout
            </Button>
            <Button
              variant="ghost"
              size="sm"
              colorScheme="gray"
              onClick={() => handleCancelCustomise()}
            >
              Cancel
            </Button>
          </HStack>
        </Flex>
        )}
        <Stack direction={{ base: 'column', md: 'row' }} mb={4} width="100%" justify="space-between">
          <Heading as="h1">
            {pageTitle}
            <InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />
          </Heading>
          {!isCustomising && hasDeveloperRole && (
          <HStack
            py={1}
            pl={2}
            pr={1}
            border="1px solid"
            borderColor="gray.100"
            borderRadius="base"
          >
            <>
              {!isCustomView && (
                <HStack spacing={2}>
                  <Text fontSize="sm">Advanced view</Text>
                  <Switch
                    colorScheme="brand.orange"
                    size="sm"
                    onChange={(e) => setViewType(e.target.checked ? 'advanced' : 'basic')}
                    isChecked={viewType === 'advanced'}
                  />
                </HStack>
              )}
              {isCustomView && (
                <Button
                  leftIcon={<Icon as={FiRotateCw} boxSize={4} />}
                  variant="ghost"
                  size="sm"
                  colorScheme="gray"
                  onClick={() => handleResetLayout()}
                >
                  Reset to Default
                </Button>
              )}
              <Button
                leftIcon={<Icon as={FiSliders} boxSize={4} />}
                variant="ghost"
                size="sm"
                colorScheme="gray"
                onClick={() => setIsCustomising(!isCustomising)}
              >
                Customise Layout
              </Button>
            </>
          </HStack>
          )}
        </Stack>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext
            items={widgetList}
            strategy={rectSortingStrategy}
          >
            <SimpleGrid
              spacing={4}
              columns={{
                base: 1, sm: 2, md: 3, lg: 4, xl: 5, '2xl': 5, '3xl': 6, '4xl': 8,
              }}
            >
              {widgetList.map((key) => {
                const WidgetComponent = widgetsMap[key];
                return (
                  WidgetComponent ? (
                    <SortableItem key={key} id={key} isCustomising={isCustomising}>
                      <Box height="100%" position="relative">
                        {isCustomising
                        && (
                        <IconButton
                          position="absolute"
                          top={2}
                          right={2}
                          size="sm"
                          variant="solid"
                          colorScheme="gray"
                          icon={<Icon as={FiEye} boxSize={4} />}
                          pointerEvents="auto"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSetHidden(key);
                          }}
                          zIndex={200}
                        />
                        )}
                        <WidgetComponent key={key} />
                      </Box>
                    </SortableItem>
                  ) : null
                );
              })}
              {isCustomising && hiddenReports.map((key) => {
                const WidgetComponent = widgetsMap[key];
                return (
                  WidgetComponent ? (
                    <SortableItem key={key} id={key} isCustomising={isCustomising} isHidden>
                      <Box position="relative" height="100%">
                        <IconButton
                          position="absolute"
                          top={2}
                          right={2}
                          size="sm"
                          variant="solid"
                          colorScheme="gray"
                          icon={<Icon as={FiEyeOff} boxSize={4} />}
                          onClick={() => handleSetVisible(key)}
                          zIndex={100}
                        />
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          zIndex={50}
                          background="rgba(255, 255, 255, 0.8)"
                        />
                        <WidgetComponent key={key} />
                      </Box>
                    </SortableItem>
                  ) : null
                );
              })}
            </SimpleGrid>
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <Box position="relative" height="100%" boxShadow="lg">
                <IconButton
                  position="absolute"
                  top={2}
                  right={2}
                  size="sm"
                  variant="solid"
                  colorScheme="gray"
                  icon={<Icon as={FiEye} boxSize={4} />}
                  onClick={() => null}
                  zIndex={100}
                />
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  zIndex={100}
                  cursor="grabbing"
                />
                {createElement(widgetsMap[activeId], { key: activeId })}
              </Box>
            ) : null}
          </DragOverlay>
          {((viewType === 'advanced' && !isCustomising) || (isCustomView && customDashboardLayout?.showNetworkStats && !isCustomising)) && (
            <StatsWidget />
          )}
          {isCustomising && (
            <Flex
              p={2}
              mt={4}
              border="1px solid"
              borderColor="gray.100"
              borderRadius="md"
              flexDir="column"
            >
              <IconButton
                size="sm"
                variant="solid"
                colorScheme="gray"
                icon={<Icon as={tempShowNetworkStats ? FiEye : FiEyeOff} boxSize={4} />}
                onClick={() => setTempShowNetworkStats(!tempShowNetworkStats)}
                zIndex={100}
                justifySelf="flex-end"
                alignSelf="flex-end"
              />
              <Box opacity={tempShowNetworkStats === true ? 1 : 0.4}>
                <StatsWidget />
              </Box>
            </Flex>
          )}
        </DndContext>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={helpHtml}
          index={index}
          setIndex={setIndex}
        />
        <ConfirmDialog />
      </Container>
    </MainLayout>
  );
}
