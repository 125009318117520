import {
  Container,
  Heading,
  Box,
  Text,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Grid,
  GridItem,
  useTheme,
} from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { getPercent, roundToDecimal } from '@src/utils/math';
import { selectedDatabaseIdState, usePortalNavContext } from '@transport-insights/uikit';
import { isEmpty } from 'lodash';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import EmptyState from '@src/components/shared/EmptyState';
import MainLayout from '@src/components/layouts/main';
import { useReports } from '@src/modules/onf/context/reports';
import { useNetworkSelector } from '@src/modules/onf/context/network-selector';
import HnoFilter from '../components/shared/HnoFilter';

export default function NetworkCharacteristics() {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [params, setParams] = useState({ networks: [], hno: [] });
  const { isLoading: isLoadingNav } = usePortalNavContext();
  const { data: reportData, isLoading: isLoadingReport, refetch } = useReports('networkChar', params);
  const categories = [...new Set(data.map((x) => x.category))];
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const { data: selectorData, isLoading: isLoadingSelectorData } = useNetworkSelector();
  let urbanTotals = data.filter((x) => x.streetFamily === 'Urban')
    .reduce((acc, item) => ({
      ...acc,
      length: acc.length + item.length,
      sealed: acc.sealed + item.sealed,
      unsealed: acc.unsealed + item.unsealed,
      lane: acc.lane + item.lane,
      journeys: acc.journeys + item.journeys,
    }), {
      length: 0, sealed: 0, unsealed: 0, lane: 0, journeys: 0,
    });
  // Round them
  urbanTotals = {
    length: roundToDecimal(urbanTotals.length, 1),
    sealed: roundToDecimal(urbanTotals.sealed, 1),
    unsealed: roundToDecimal(urbanTotals.unsealed, 1),
    lane: roundToDecimal(urbanTotals.lane, 1),
    journeys: roundToDecimal(urbanTotals.journeys, 1),
  };
  let ruralTotals = data.filter((x) => x.streetFamily === 'Rural')
    .reduce((acc, item) => ({
      ...acc,
      length: acc.length + item.length,
      sealed: acc.sealed + item.sealed,
      unsealed: acc.unsealed + item.unsealed,
      lane: acc.lane + item.lane,
      journeys: acc.journeys + item.journeys,
    }), {
      length: 0, sealed: 0, unsealed: 0, lane: 0, journeys: 0,
    });
  // Round them
  ruralTotals = {
    length: roundToDecimal(ruralTotals.length, 1),
    sealed: roundToDecimal(ruralTotals.sealed, 1),
    unsealed: roundToDecimal(ruralTotals.unsealed, 1),
    lane: roundToDecimal(ruralTotals.lane, 1),
    journeys: roundToDecimal(ruralTotals.journeys, 1),
  };
  let totals = data.reduce((acc, item) => ({
    ...acc,
    length: acc.length + item.length,
    sealed: acc.sealed + item.sealed,
    unsealed: acc.unsealed + item.unsealed,
    lane: acc.lane + item.lane,
    journeys: acc.journeys + item.journeys,
  }), {
    length: 0, sealed: 0, unsealed: 0, lane: 0, journeys: 0,
  });
  // Round them
  totals = {
    length: roundToDecimal(totals.length, 1),
    sealed: roundToDecimal(totals.sealed, 1),
    unsealed: roundToDecimal(totals.unsealed, 1),
    lane: roundToDecimal(totals.lane, 1),
    journeys: roundToDecimal(totals.journeys, 1),
  };

  useEffect(() => {
    if (!isEmpty(reportData)) {
      setData(reportData.data);
    } else {
      setData([]);
    }
  }, [reportData]);

  const urbanColor = theme.colors.gray[500];
  const ruralColor = theme.colors.orange[800];

  const ruralCategoryCount = data.filter((x) => x.streetFamily === 'Rural').length;
  const urbanCategoryCount = data.filter((x) => x.streetFamily === 'Urban').length;

  const handleApplyHnoFilter = (val) => {
    const hno = [val];
    setParams({ ...params, hno });
    refetch();
  };

  if (isLoadingReport || isLoadingNav || isLoadingSelectorData || isEmpty(reportData)) {
    return (
      <MainLayout>
        <LoadingSpinner />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Container maxW="full">
        <Heading as="h1">Network Characteristics</Heading>
        {Number.parseInt(rcaId, 10) === 81
          && (
          <Box
            p={4}
            mt={4}
            mb={2}
            background="white"
            border="1px solid"
            borderColor="gray.100"
            borderRadius="md"
            boxShadow="sm"
          >
            <Text fontWeight="bold" fontSize="lg" mb={3}>State Highway Filter</Text>
            <HnoFilter
              selectedHno={(hno) => handleApplyHnoFilter(hno)}
              data={selectorData?.hnos}
              isLoading={isLoadingSelectorData}
              activeHnoFilter={reportData.reportParams?.hno[0]}
              buttonLabel="Apply"
            />
          </Box>
          )}
        {isEmpty(reportData?.data)
          && <EmptyState title="No Data" message="Sorry there is no data available for selected network" />}
        {!isEmpty(reportData?.data)
          && (
          <>
            <Box my={4}>
              <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                <GridItem
                  rowSpan={2}
                  p={2}
                  background="white"
                  border="1px solid"
                  borderColor="gray.100"
                  borderRadius="md"
                  boxShadow="sm"
                  minH="280"
                >
                  <Chart
                    options={
                      {
                        title: {
                          text: 'Length by Category',
                        },
                        chart: {
                          toolbar: {
                            show: false,
                          },
                          stacked: true,
                          stackType: '100%',
                        },
                        xaxis: {
                          categories: [''],
                        },
                        yaxis: [
                          {
                            forceNiceScale: true,
                            tickAmount: 8,
                            reversed: true,
                            labels: {
                              formatter(val) {
                                return `${val ? val.toFixed(0) : val}%`;
                              },
                            },
                          },
                        ],
                        colors: categories.map((cat) => theme.colors.onfCategory[cat].base),
                        labels: categories,
                        tooltip: {
                          enabled: true,
                          y: {
                            formatter: (value) => `${value} km`,
                          },
                        },
                        legend: {
                          position: 'left',
                          formatter: (seriesName, opts) => `${seriesName} (${opts.w.globals.series[opts.seriesIndex]} km)`,
                          offsetY: 30,
                        },
                        dataLabels: {
                        },
                      }
                    }
                    series={data.map((x) => ({ data: [x.length.toFixed(1)], name: x.category }))}
                    type="bar"
                    width="100%"
                    height="100%"
                  />
                </GridItem>
                <GridItem
                  colSpan={2}
                  p={2}
                  background="white"
                  border="1px solid"
                  borderColor="gray.100"
                  borderRadius="md"
                  boxShadow="sm"
                  minH="380"
                >
                  <Chart
                    options={
                      {
                        title: {
                          text: 'Network Length vs Vehicle Journeys',
                        },
                        chart: {
                          toolbar: {
                            show: false,
                          },
                        },
                        yaxis: [
                          {
                            forceNiceScale: true,
                            tickAmount: 10,
                            labels: {
                              formatter(val) {
                                return `${val ? Math.round(val) : val}%`;
                              },
                            },
                          },
                        ],
                        xaxis: {
                          labels: {
                            style: {
                              colors: categories.map((cat) => theme.colors.onfCategory[cat].base),
                              fontWeight: 'bold',
                              fontSize: 10,
                            },
                          },
                        },
                        labels: categories,
                        tooltip: {
                          shared: true,
                          intersect: false,
                          enabled: true,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        legend: {
                          position: 'bottom',
                          itemMargin: {
                            vertical: 20,
                          },
                        },
                      }
                    }
                    series={[{ name: 'Length', data: data.map((x) => getPercent(x.length, totals.length)) },
                      { name: 'Journeys', data: data.map((x) => getPercent(x.journeys, totals.journeys)) },
                    ]}
                    type="bar"
                    width="100%"
                    height="100%"
                  />
                </GridItem>
                <GridItem
                  p={2}
                  background="white"
                  border="1px solid"
                  borderColor="gray.100"
                  borderRadius="md"
                  boxShadow="sm"
                  minH="320"
                >
                  <Chart
                    options={
                      {
                        title: {
                          text: 'Rural vs Urban',
                        },
                        chart: {
                          toolbar: {
                            show: false,
                          },
                        },
                        labels: ['Rural', 'Urban'],
                        colors: [ruralColor, urbanColor],
                        tooltip: {
                          enabled: false,
                        },
                        legend: {
                          position: 'bottom',
                          formatter: (seriesName, opts) => `${seriesName} (${opts.w.globals.series[opts.seriesIndex]} km)`,
                        },
                        dataLabels: {
                          formatter: (val) => `${Math.round(val)}%`,
                        },
                      }
                    }
                    series={
                      [
                        ruralTotals.length,
                        urbanTotals.length,
                      ]
                    }
                    type="pie"
                    width="100%"
                    height="100%"
                  />
                </GridItem>
                <GridItem
                  p={2}
                  background="white"
                  border="1px solid"
                  borderColor="gray.100"
                  borderRadius="md"
                  boxShadow="sm"
                  minH="320"
                >
                  <Chart
                    options={
                      {
                        title: {
                          text: 'Sealed vs Unsealed',
                        },
                        chart: {
                          toolbar: {
                            show: false,
                          },
                        },
                        labels: ['Sealed', 'Unsealed'],
                        colors: ['#222222', '#666666'],
                        tooltip: {
                          enabled: false,
                        },
                        legend: {
                          position: 'bottom',
                          formatter: (seriesName, opts) => `${seriesName} (${opts.w.globals.series[opts.seriesIndex]} km)`,
                        },
                        dataLabels: {
                          formatter: (val) => `${Math.round(val)}%`,
                        },
                      }
                    }
                    series={
                      [
                        totals.sealed,
                        totals.unsealed,
                      ]
                    }
                    type="pie"
                    width="100%"
                    height="100%"
                  />
                </GridItem>
              </Grid>
            </Box>
            <Box border="1px solid" borderColor="gray.100" my={4}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr background="gray.50">
                      <Th />
                      <Th>ONF Category</Th>
                      <Th>Total Length (km)</Th>
                      <Th>Total Length (%)</Th>
                      <Th>Sealed (km)</Th>
                      <Th>Unsealed (km)</Th>
                      <Th>Lane (km)</Th>
                      <Th>Vehicle Journeys (m vkt)</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td
                        rowSpan={urbanCategoryCount + 2}
                        maxWidth={2}
                        background={urbanColor}
                        padding={0}
                      >
                        <Text transform="rotate(-90deg)" textAlign="center" color="white" fontWeight="bold">URBAN</Text>
                      </Td>
                    </Tr>
                    {
                      data.filter((x) => x.streetFamily === 'Urban').map((row, i) => (
                        <Tr key={`cat_${i}`}>
                          <Td color={theme.colors.onfCategory[row.category].base} fontWeight="bold">
                            {row.category}
                          </Td>
                          <Td>
                            {roundToDecimal(row.length, 1)}
                          </Td>
                          <Td>
                            {getPercent(row.length, totals.length)}
                            %
                          </Td>
                          <Td>
                            {roundToDecimal(row.sealed, 1)}
                          </Td>
                          <Td>
                            {roundToDecimal(row.unsealed, 1)}
                          </Td>
                          <Td>
                            {roundToDecimal(row.lane, 1)}
                          </Td>
                          <Td>
                            {roundToDecimal(row.journeys, 1)}
                          </Td>
                        </Tr>
                      ))
                    }
                    <Tr fontWeight="bold" background={urbanColor} color="white">
                      <Td>
                        Total Urban Network
                      </Td>
                      <Td>
                        {urbanTotals.length}
                      </Td>
                      <Td>
                        {getPercent(urbanTotals.length, totals.length)}
                        %
                      </Td>
                      <Td>
                        {urbanTotals.sealed}
                      </Td>
                      <Td>
                        {urbanTotals.unsealed}
                      </Td>
                      <Td>
                        {urbanTotals.lane}
                      </Td>
                      <Td>
                        {urbanTotals.journeys}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td
                        rowSpan={ruralCategoryCount + 2}
                        maxWidth={2}
                        background={ruralColor}
                        padding={0}
                      >
                        <Text transform="rotate(-90deg)" textAlign="center" color="white" fontWeight="bold">RURAL</Text>
                      </Td>
                    </Tr>
                    {
                      data.filter((x) => x.streetFamily === 'Rural').map((row, i) => (
                        <Tr key={`cat_${i}`}>
                          <Td color={theme.colors.onfCategory[row.category].base} fontWeight="bold">
                            {row.category}
                          </Td>
                          <Td>
                            {roundToDecimal(row.length, 1)}
                          </Td>
                          <Td>
                            {getPercent(row.length, totals.length)}
                            %
                          </Td>
                          <Td>
                            {roundToDecimal(row.sealed, 1)}
                          </Td>
                          <Td>
                            {roundToDecimal(row.unsealed, 1)}
                          </Td>
                          <Td>
                            {roundToDecimal(row.lane, 1)}
                          </Td>
                          <Td>
                            {roundToDecimal(row.journeys, 1)}
                          </Td>
                        </Tr>
                      ))
                    }
                    <Tr fontWeight="bold" background={ruralColor} color="white">
                      <Td>
                        Total Rural Network
                      </Td>
                      <Td>
                        {ruralTotals.length}
                      </Td>
                      <Td>
                        {getPercent(ruralTotals.length, totals.length)}
                        %
                      </Td>
                      <Td>
                        {ruralTotals.sealed}
                      </Td>
                      <Td>
                        {ruralTotals.unsealed}
                      </Td>
                      <Td>
                        {ruralTotals.lane}
                      </Td>
                      <Td>
                        {ruralTotals.journeys}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td rowSpan="2" maxWidth={2} background="gray.800" borderBottom="none" />
                    </Tr>
                    {
                      data.filter((x) => x.category === 'Unclassified').map((row, i) => (
                        <Tr key={`cat_${i}`}>
                          <Td fontWeight="bold">
                            {row.category}
                          </Td>
                          <Td>
                            {roundToDecimal(row.length, 1)}
                          </Td>
                          <Td>
                            {getPercent(row.length, totals.length)}
                            %
                          </Td>
                          <Td>
                            {roundToDecimal(row.sealed, 1)}
                          </Td>
                          <Td>
                            {roundToDecimal(row.unsealed, 1)}
                          </Td>
                          <Td>
                            {roundToDecimal(row.lane, 1)}
                          </Td>
                          <Td>
                            {roundToDecimal(row.journeys, 1)}
                          </Td>
                        </Tr>
                      ))
                    }
                    <Tr fontWeight="bold" background="gray.800" color="white">
                      <Td />
                      <Td>
                        Total Network
                      </Td>
                      <Td>
                        {totals.length}
                      </Td>
                      <Td>
                        100%
                      </Td>
                      <Td>
                        {totals.sealed}
                      </Td>
                      <Td>
                        {totals.unsealed}
                      </Td>
                      <Td>
                        {totals.lane}
                      </Td>
                      <Td>
                        {totals.journeys}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </>
          )}
      </Container>
    </MainLayout>
  );
}
